import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ChartConfiguration, ChartDataset, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'dsh-chart-line',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './line-chart.component.html',
  styleUrl: './line-chart.component.scss',
})
export class ChartLineComponent implements OnInit, OnChanges {
  @Input() chartData!: any[];
  @Input() heightPx: string = '190';
  @Input() xScale: string = '';
  @Input() yScale: string = '';
  @Input() showLegend: boolean = false;

  chartDataConfiguration: ChartConfiguration<'line'>['data'] = {
    datasets: [],
  };

  lineChartOptions!: ChartOptions<'line'>;

  @ViewChild(BaseChartDirective) chartDirective?: BaseChartDirective;
  @ViewChild('canvasRef') canvasRef?: ElementRef<HTMLCanvasElement>;

  constructor() {
    this.setChartOptions();
  }
  ngOnInit(): void {
    this.updateChartLineData(this.chartData);
    this.updateChartColors();
    this.setChartOptions();

    this.lineChartOptions.plugins!.legend!.display = this.showLegend;
  }

  ngOnChanges(): void {
    this.updateChartLineData(this.chartData);
    this.updateChartColors();
  }
  ngAfterViewInit() {
    this.updateChartColors();
  }

  private updateChartLineData(newData: any[]) {
    if (!newData || newData.length === 0) {
      return;
    }

    this.chartDataConfiguration.labels = newData.map(
      (data: any) => data.day ?? ''
    );
    this.chartDataConfiguration.datasets = [];

    this.chartDataConfiguration.datasets.push({
      data: newData.map((data) => data.value),
    });
  }

  private updateChartColors() {
    const ctx = this.canvasRef?.nativeElement.getContext('2d');
    if (ctx) {
      this.chartDataConfiguration.datasets.forEach(
        (dataset: ChartDataset<'line'>) => {
          dataset.borderColor = '#f76902';
          dataset.pointBackgroundColor = '#f76902';
          dataset.pointBorderColor = '#f76902';
          dataset.pointRadius = 3;
          dataset.tension = 0.5
        }
      );

      this.chartDirective?.update();
    }
  }

  private setChartOptions() {
    this.lineChartOptions = {
      responsive: true,
      scales: {
        y: {
          grid: {
            display: true,
            color: 'rgba(255,255,255,0.1)',
          },
          title: {
            color: '#9398a3',
            display: true,
            text: this.yScale,
            font: {
              weight: 'bold',
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          title: {
            color: '#9398a3',
            display: true,
            align: 'end',
            text: this.xScale,
            font: {
              weight: 'bold',
            },
          },
        },
      },

      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
        tooltip: {
          enabled: true,
        },
        title: {
          display: false,
        },
      },
    };
  }
}
