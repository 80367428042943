import { Injectable } from '@angular/core';

export enum StorageKey {
  ClientFilters = 'clientFilters',
  Product = 'product',
  ProductFilter = 'productFilter',
  ClientEmail = 'clientEmail',
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage;

  constructor() {
    this.storage = sessionStorage;
  }

  saveData(key: StorageKey, data: any): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  getData(key: StorageKey): any {
    const data = this.storage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  removeData(key: StorageKey): void {
    this.storage.removeItem(key);
  }

  clearData(): void {
    caches
      .keys()
      .then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
  }
}
