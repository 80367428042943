@if (seller && seller.id) {
<div class="item-seller">
  <div class="type-logo">
    <div class="logo-container">
      <mat-icon
        [inline]="true"
        class="material-icons logo"
        [svgIcon]="'dsh-ic-person'"
      >
      </mat-icon>
      <span class="seller-code">{{ seller.code }}</span>
    </div>
  </div>
  <div class="info">
    <span class="title">{{ seller.name }}</span>

    <div class="row">
      <span class="semibold"
        >Últ. acesso:
        <span class="subtitle">{{
          seller.lastConnection | date : "dd/MM/yyyy"
        }}</span>
      </span>
      <span class="semibold"
        >Conexoes mensais:
        <span class="subtitle">{{ seller.monthlyConnections }}</span></span
      >
    </div>
  </div>
  <div class="access-logo" (click)="openSeller()">
    <div class="logo-container">
      <mat-icon
        [inline]="true"
        class="material-icons logo"
        svgIcon="ic-right-circle"
      >
      </mat-icon>
    </div>
  </div>
</div>
}
