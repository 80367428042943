import { Component, Input } from '@angular/core';
import { SellerDetail } from '../../../modules/seller/seller.model';

@Component({
  selector: 'dsh-seller-sell-metrics',
  templateUrl: './dsh-seller-sell-metrics.component.html',
  styleUrls: ['./dsh-seller-sell-metrics.component.scss'],
})
export class SellerSellMetricsComponent {
  @Input() seller!: SellerDetail;
  searchedClientsText: string = '';
  notSearchedClientsText: string = '';

  uniqueClientsSoldToText: string = '';
  uniqueProductsCategoriesSoldText: string = '';
  uniqueProductsSoldText: string = '';
  previousMonth: any = '';
  constructor() {}

  ngOnInit() {
    this.searchedClientsText = this.percentageText(
      this.seller.uniqueClientsSearched,
      this.seller.totalClients
    );
    this.notSearchedClientsText = this.percentageText(
      this.seller.clientsNotSearched,
      this.seller.totalClients
    );

    this.uniqueClientsSoldToText = this.percentageText(
      this.seller.uniqueClientsSoldTo,
      this.seller.totalClients
    );
    this.uniqueProductsCategoriesSoldText = this.percentageText(
      this.seller.uniqueProductsCategoriesSold,
      this.seller.totalCategories
    );
    this.uniqueProductsSoldText = this.percentageText(
      this.seller.uniqueProductsSold,
      this.seller.totalProducts
    );

    const x = new Date();
    x.setDate(0);
    this.previousMonth = x;
  }

  private percentageText = (number: number, total: number): string => {
    return total > 0
      ? `${number} de ${total} (${Math.round((number * 10000) / total) / 100}%)`
      : '';
  };
}
