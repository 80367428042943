<div class="header-container">
  @if (showsuggesion$ | async) {
  <mat-toolbar class="header">
    <div class="manifest">
      <div class="close">
        <mat-icon
          (click)="close()"
          [inline]="true"
          class="material-icons close"
          svgIcon="ic-close"
        >
        </mat-icon>
      </div>
      <div class="content">
        <mat-icon [inline]="true" class="material-icons" svgIcon="ic-logo">
        </mat-icon>
      </div>
      <div class="android">
        <span>para Android</span>
      </div>
      <div class="button">
        <button mat-raised-button (click)="install()">
          <span class="text-button">INSTALAR</span>
        </button>
      </div>
    </div>
  </mat-toolbar>
  } @switch (getHeaderType()) { @case (HeaderTypeEnum.HOME) {
  <mat-toolbar class="home-header">
    <div class="content">
      <div class="logo">
        <div class="logo-info">
          <img class="suvinil" src="assets/logo.svg" alt="logo" />
          <div class="line"></div>
          <img class="glasu" src="assets/logo-glasu.svg" alt="logo-glasu" />
        </div>
        @if (showNetworkStatus$ | async) {
        <div class="subject-column" style="margin-left: 38px">
          <mat-icon
            (click)="usabilityManual()"
            [inline]="true"
            class="material-icons help"
            svgIcon="ic-help"
          >
          </mat-icon>
        </div>
        }
        <span class="logout" (click)="logout()">Sair</span>
      </div>

      <div class="version">
        <span class="name">Vendas</span>
        <span class="number">4.0</span>
      </div>
      <div class="subject-content">
        <div class="subject-column separate">
          <div class="subject">
            <div class="welcome">
              <div class="name">Olá, {{ profileName }}</div>
            </div>
            <div class="company">Distribuidora <br/> Premium</div>
          </div>
          @if (!(showNetworkStatus$ | async)) {
          <div class="subject-column">
            <div class="button">
              <button mat-raised-button>
                <mat-icon
                  class="material-icons icon"
                  [svgIcon]="'ic-offline'"
                  [inline]="true"
                ></mat-icon>
                <span class="text-button">OFFLINE</span>
              </button>
            </div>
          </div>
          } @else { @if (userType !==UserTypeEnum.SELLER) {
          <div class="button dashboard">
            <button mat-raised-button (click)="dashboard()">
              <mat-icon
                class="material-icons icon"
                [svgIcon]="'dsh-ic-icon'"
                [inline]="true"
              ></mat-icon>
              <span class="text-button">Painel de </span>
              <span class="text-button sub-margin"> Controle</span>
            </button>
          </div>
          } }
        </div>
      </div>
    </div>
  </mat-toolbar>
  } @case (HeaderTypeEnum.FILTER) {
  <mat-toolbar class="header">
    <div class="content filter">
      <mat-icon
        (click)="backButton()"
        [inline]="true"
        class="material-icons back"
        svgIcon="ic-close-square"
      >
      </mat-icon>
    </div>
  </mat-toolbar>
  } @case (HeaderTypeEnum.OTHER) {
  <mat-toolbar class="other-header">
    <div class="content">
      <div class="logo">
        <img class="image" src="assets/logo.svg" alt="logo" />
        <div class="line"></div>
        <img class="image glasu" src="assets/logo-glasu.svg" alt="logo-glasu" />
      </div>
      <div class="version">
        <span class="name">Vendas</span>
        <span class="number">4.0</span>
      </div>
    </div>
    <div class="back-content">
      <mat-icon
        (click)="backButton()"
        [inline]="true"
        class="material-icons back"
        svgIcon="ic-arrow-left-circle"
      >
      </mat-icon>
    </div>
  </mat-toolbar>
  } }
</div>
