import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiBridgeService } from 'src/app/core/api-bridge.service';
import { IndicatorPercentage, Seller, SellerDetail } from './seller.model';

@Injectable({
  providedIn: 'root',
})
export class SellerService {
  constructor(private apibridge: ApiBridgeService) {
  }

  getSellers(): Observable<Seller[]> {
    return this.apibridge.get('supervisor/sellers').pipe(
      map((response: any) => {
        return response.sellers.map((seller: any) => {
          return {
            id: seller.id,
            code: seller.seller_code,
            name: seller.name + ' ' + seller.surname,
            lastConnection: seller.last_access_date,
            monthlyConnections: seller.monthly_connections,
          };
        });
      })
    );
  }

  getSeller(sellerId: string): Observable<SellerDetail> {
    return this.apibridge.get(`supervisor/sellers/${sellerId}`).pipe(
      map((response: any) => {
        const roundFeedback = (number: number) => {
          const feedbackTotal: number = response.positive_feedback + response.negative_feedback + response.neutral_feedback;
          return feedbackTotal > 0 ? Math.round(number * 10000 / feedbackTotal) / 100 : '';
        }
        const seller: SellerDetail = {
          monthlyConnectionsData: response.connections_per_day,
          mostSearchedClient: response.most_searched_client,
          uniqueClientsSearched: response.total_clients_searched,
          clientsNotSearched: response.total_clients - response.total_clients_searched,
          pctPositiveFeedback: `${roundFeedback(response.positive_feedback)}`,
          pctNegativeFeedback: `${roundFeedback(response.negative_feedback)}`,
          pctNullFeedback: `${roundFeedback(response.neutral_feedback)}`,

          uniqueClientsSoldTo: response.unique_clients_sold_to,
          totalClients: response.total_clients,
          uniqueProductsCategoriesSold: response.unique_products_categories_sold,
          totalCategories: response.total_categories,
          uniqueProductsSold: response.unique_products_sold,
          totalProducts: response.total_products,
          coverage: response.coverage,
          precision: response.precision,
        }
        return seller;
      })
    );
  }

  getGlobalIndicator(): Observable<IndicatorPercentage> {
    return this.apibridge.get('supervisor/global_indicators').pipe(
      map((response: any) => {
        return this.calculateIndicatorPercentages(response);
      })
    );
  }

  private calculateIndicatorPercentages(data: {
    proccesed_month: string;
    positive_feedback: number;
    negative_feedback: number;
    neutral_feedback: number;
    total_clients: number;
    analyzed_clients: number;
  }): IndicatorPercentage {
    const {
      positive_feedback,
      negative_feedback,
      neutral_feedback,
      total_clients,
      analyzed_clients,
    } = data;
    const totalFeedback =
      positive_feedback + negative_feedback + neutral_feedback;

    const positive_percentage =
      totalFeedback === 0
        ? '0'
        : ((positive_feedback / totalFeedback) * 100).toFixed(2);
    const negative_percentage =
      totalFeedback === 0
        ? '0'
        : ((negative_feedback / totalFeedback) * 100).toFixed(2);
    const neutral_percentage =
      totalFeedback === 0
        ? '0'
        : ((neutral_feedback / totalFeedback) * 100).toFixed(2);
    const analyzed_percentage =
      total_clients === 0 ? 0 : (analyzed_clients / total_clients) * 100;

    return {
      positive_percentage,
      negative_percentage,
      neutral_percentage,
      analyzed_percentage,
      total_clients,
      analyzed_clients,
    };
  }
}
