import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, EMPTY, Observable } from 'rxjs';
import {
  ApplicationInsightsService,
  EventName,
} from 'src/app/core/application-insights.service';
import { NetworkService } from 'src/app/core/network.service';
import { Status } from '../../../shared/components/alert-message/alert-message.component';
import { MonthlyConnections, Seller, SellerDetail } from '../seller.model';
import { SellerService } from '../seller.service';

import { ChartConfiguration, ChartOptions } from 'chart.js';
import { SellerKey } from '../../../shared/components/dsh-seller-item/seller-item.component';

@Component({
  selector: 'app-detail-list',
  templateUrl: './seller-detail.component.html',
  styleUrls: ['./seller-detail.component.scss'],
})
export class SellerDetailComponent implements OnInit {
  status: Status = Status.Warning;
  noSellers: boolean = false;
  showNetworkStatus$!: Observable<boolean>;
  offlineStatus: Status = Status.Offline;

  seller$!: Observable<SellerDetail>;
  searchText: string = '';
  messageList: string = 'Vendedor não encontrado.';
  elements: any[] = ['id', 'code', 'lastConnection', 'monthlyConnections'];
  date = new Date();
  sellerId: string = '';

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        data: [65, 59, 80, 81, 56, 55, 40],
        label: 'Series A',
        fill: true,
        tension: 0.5,
        borderColor: 'black',
        backgroundColor: 'rgba(255,0,0,0.3)',
      },
    ],
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: false,
  };
  public lineChartLegend = true;

  sellerGeneralData!: Seller;
  constructor(
    private sellerService: SellerService,
    private route: ActivatedRoute,
    private applicationInsightsService: ApplicationInsightsService,
    private networkService: NetworkService,
    private routerService: Router
  ) {
    this.showNetworkStatus$ = this.networkService.networkStatus;
  }

  ngOnInit(): void {
    this.sellerId = this.route.snapshot.paramMap.get('sellerId')!;
    this.seller$ = this.sellerService.getSeller(this.sellerId).pipe(
      catchError(() => {
        this.noSellers = true;
        this.applicationInsightsService.logEvent(EventName.NotFoud, {
          stateNotFound: 'seller',
        });
        return EMPTY;
      })
    );

    this.getSellerGeneralData();
  }

  getSellerGeneralData() {
    const data = localStorage.getItem(SellerKey.Seller);
    if (data) {
      this.sellerGeneralData = JSON.parse(data);
    } else {
      this.routerService.navigate(['seller/list']);
    }
  }

  searchbox(value: string): void {
    this.searchText = value;
  }

  toSorted(sellers: Seller[]): Seller[] {
    return sellers.sort((a, b) => b.monthlyConnections - a.monthlyConnections);
  }

  hasConnections(connections: MonthlyConnections[]) {
    return connections.every((item: any) => item.value === 0);
  }
}
