import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Seller } from '../../../modules/seller/seller.model';
export enum SellerKey {
  Seller = 'seller',
}
@Component({
  selector: 'dsh-seller-item',
  templateUrl: './seller-item.component.html',
  styleUrls: ['./seller-item.component.scss'],
})
export class SellerItemComponent {
  @Input() seller!: Seller;

  constructor(private routerService: Router) {}
  openSeller(): void {
    this.saveData(this.seller);
    this.routerService.navigate(['seller', this.seller.id]);
  }

  saveData(seller: Seller) {
    localStorage.removeItem(SellerKey.Seller);
    localStorage.setItem(SellerKey.Seller, JSON.stringify(seller));
  }
}
