@if (seller$ | async; as data) {
  <div class="seller-header">
    <h1>Detalhes do vendedor</h1>
    <div class="seller-detail">
      <div class="type-logo">
        <div class="logo-container">
          <mat-icon
            [inline]="true"
            class="material-icons logo"
            [svgIcon]="'dsh-ic-person'"
          >
          </mat-icon>
        </div>
      </div>
      @if (sellerGeneralData) {
        <div class="info">
          <span class="title">{{ sellerGeneralData.name }}</span>
          <span class="semibold">Código: <span class="subtitle">{{ sellerGeneralData.code }}</span></span>

          <div class="row">
            <span class="semibold">Último acesso: <span class="subtitle">{{ sellerGeneralData.lastConnection | date : "dd/MM/yyyy" }}</span></span>
            <span class="semibold">Conexoes mensais: <span class="subtitle">{{ sellerGeneralData.monthlyConnections }}</span></span>
          </div>
        </div>
      }
    </div>
    <div class="list-item">
      @if (!(showNetworkStatus$ | async)) {
        <app-alert-message [status]="offlineStatus"></app-alert-message>
      }
    </div>
  </div>
  <div class="chart-container">
    @if (!hasConnections(data.monthlyConnectionsData)) {
      <h2>Conexiones mensuais</h2>
      <dsh-chart-line [chartData]="data.monthlyConnectionsData" [xScale]="'Dias do mês'" [yScale]="'Quantia'"></dsh-chart-line>
    } @else {
      <p>O vendedor ainda não tem conexões</p>
    }
    <h2>Devolução do vendedor</h2>
    <dsh-seller-feedback [positiveFeedback]="data.pctPositiveFeedback" [negativeFeedback]="data.pctNegativeFeedback" [nullFeedback]="data.pctNullFeedback"></dsh-seller-feedback>
  </div>
  <div>
    <dsh-seller-sell-metrics [seller]="data"></dsh-seller-sell-metrics>
  </div>
}
